<template>
    <div>
        <validation-observer v-if="object" ref="validate">
            <b-form class="form">
                <b-form-group class="mt-1" label="Avtor">
                    <validation-provider #default="{ errors }" name="avtor" rules="required|min:2|max:50">
                        <b-form-input v-model="object.author" name="author"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                
                <b-form-group class="mt-1" label="Vprašanje">
                    <validation-provider #default="{ errors }" name="vprasanje" rules="required|max:5000">
                        <b-form-textarea placeholder="Vnesite vprašanje..." v-model="object.question" rows="5"></b-form-textarea>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group class="mt-1" label="Odgovor">
                    <validation-provider #default="{ errors }" name="odgovor" rules="required|max:5000">
                        <b-form-textarea placeholder="Vnesite odgovor..." v-model="object.answer" rows="5"></b-form-textarea>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-checkbox v-model="object.show">Prikaži vprašanje in odgovor</b-form-checkbox>
                
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import {
        BFormInput,
        BFormTextarea,
        BFormCheckbox,
        BFormGroup,
        BForm
    } from 'bootstrap-vue'
    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            BFormInput,
            BFormTextarea,
            BFormCheckbox,
            BFormGroup,
            BForm
        },
        props: {
            model: {
                type: Object,
                default: null
            },
            type: {
                type: String,
                dafault: 'add'
            }
        },
        computed: {
            object: {
                get() {
                    return this.model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        data() {
            return {
                required 
            }
        },
        methods:{
        
        }
    }
</script>